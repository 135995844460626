import cx from "@hsl/core/utils/cx";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import FundPageContent from "../../../components/FundPageContent";
import Skeleton from "../../../components/Skeletons";
import Spinner from "../../../components/Spinner";
import { useManagerDiaryContext } from "../context";
import Author from "./Author";
import Disclaimer from "./Disclaimer";
import { CompanyPillCheckbox } from "./Filters/CompanyPillCheckBox";
import ManagerDiaryTextDataPart from "./ManagerDiaryTextDataPart";

const InvestmentViews = "Investment Views";

const Posts = ({ headshots }: { headshots?: Record<string, string> }) => {
    const {
        activeDiaryEntries,
        isLoadingEntries,
        isLoadingMore,
        addCompanyFilter,
        reverseOrder,
        loadNextPage,
        clearFilters,
    } = useManagerDiaryContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const endRef = useRef<HTMLElement>(null);
    const MAX_TAGS = 6;
    useEffect(() => {
        const target = endRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0]?.isIntersecting) {
                    void loadNextPage();
                }
            },
            {
                threshold: 1,
            }
        );
        if (target) observer.observe(target);
        return () => {
            if (target) observer.unobserve(target);
        };
    }, [endRef, loadNextPage]);

    if (isLoadingEntries) return <Skeleton height={900} />;

    const scrollTop = () => {
        if (containerRef.current) {
            window.scrollTo({
                top: containerRef.current?.offsetTop - 100,
                behavior: "smooth",
            });
        }
    };
    const toSortedEntries = (
        a: (typeof activeDiaryEntries)[0],
        b: (typeof activeDiaryEntries)[0]
    ) => {
        if (reverseOrder) {
            return dayjs(a.asAtDate).diff(dayjs(b.asAtDate));
        }
        return dayjs(b.asAtDate).diff(dayjs(a.asAtDate));
    };
    const ivLinkClasses = cx(
        "!no-underline !hover:underline !text-black flex items-center text-sm gap-x-2"
    );
    const diaryEntries = activeDiaryEntries.filter(
        (entry) =>
            entry.category.name !== InvestmentViews ||
            entry.comprehensiveSynopsis.length > 0
    );
    const noResults = (
        <span>
            There are no results for the applied filters.{" "}
            <button
                className="text-sm underline sm:text-base"
                onClick={clearFilters}
            >
                Clear filters
            </button>{" "}
            to see all results.
        </span>
    );
    return (
        <div ref={containerRef}>
            <Disclaimer
                content={diaryEntries.length > 0 ? undefined : noResults}
            />
            <ul className="flex flex-col gap-y-6">
                {diaryEntries
                    .sort(toSortedEntries)
                    .map(
                        (
                            {
                                id,
                                title,
                                category,
                                asAtDate,
                                body,
                                authors,
                                customTags,
                                comprehensiveSynopsis,
                            },
                            i
                        ) => {
                            const entryKey = `${id}-${title}-${asAtDate}-${i}`;
                            const postBody = processPostBodyEmbeds(body);
                            return (
                                <li key={entryKey} className="relative">
                                    {/* Needed to account for scroll */}
                                    <div
                                        id={`post-${id}`}
                                        className="absolute -top-48"
                                    />
                                    <FundPageContent.Box className="!p-0">
                                        <div
                                            className="flex items-center justify-end gap-2 px-4 py-3 text-xl font-normal text-black sm:rounded-t-lg sm:px-6"
                                            style={{
                                                backgroundColor:
                                                    category.colour as string,
                                            }}
                                        >
                                            <div className="flex flex-1 flex-wrap gap-6">
                                                {authors
                                                    ?.slice(0, 1)
                                                    .map((author, i) => {
                                                        const primaryHeadshot =
                                                            headshots?.[
                                                                author.name
                                                            ] ??
                                                            author.primaryHeadshot;
                                                        return (
                                                            <Author
                                                                {...author}
                                                                primaryHeadshot={
                                                                    primaryHeadshot
                                                                }
                                                                className={cx(
                                                                    i > 0 &&
                                                                        "hidden lg:flex",
                                                                    "font-normal text-black"
                                                                )}
                                                                key={`author-${author.id}`}
                                                            />
                                                        );
                                                    })}
                                            </div>
                                            <FundPageContent.Header
                                                h3
                                                className="!mb-0 text-xl font-normal tracking-normal text-black"
                                            >
                                                {category.name}
                                            </FundPageContent.Header>
                                            <span className="text-black">
                                                |
                                            </span>
                                            <span className="text-base font-normal text-black">
                                                {dayjs(asAtDate).format(
                                                    "D MMM YYYY"
                                                )}
                                            </span>
                                        </div>

                                        {customTags.length > 0 && (
                                            <div className="flex-1 pl-4 pr-8 pt-6 sm:pl-6">
                                                <div className="text-secondary pb-3 text-xs">
                                                    Reference Tags
                                                </div>
                                                <div className="flex h-min max-h-[25px] flex-wrap justify-start gap-2 overflow-hidden">
                                                    {customTags
                                                        .slice(0, MAX_TAGS)
                                                        .map((company) => (
                                                            <CompanyPillCheckbox
                                                                key={`company-pill-checkbox-${company.id}`}
                                                                value={company}
                                                                label={
                                                                    company.name
                                                                }
                                                                selected={true}
                                                                onChange={(
                                                                    company
                                                                ) => {
                                                                    const hasBeenAdded =
                                                                        addCompanyFilter(
                                                                            company
                                                                        );
                                                                    if (
                                                                        hasBeenAdded
                                                                    ) {
                                                                        scrollTop();
                                                                    }
                                                                }}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        )}

                                        <ManagerDiaryTextDataPart
                                            data={
                                                category.name ===
                                                InvestmentViews
                                                    ? `${comprehensiveSynopsis}
                                                        <br/>
                                                        <p>
                                                            <a class="${ivLinkClasses}"
                                                               href="/Posts/Read/${id}"
                                                               target="_blank"
                                                               style="color: black !important;">
                                                               Read the full Investment View
                                                               <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="15"
                                                                    height="12"
                                                                    viewBox="0 0 15 12"
                                                                    fill="none">
                                                                    <path d="M12.4668 5.14062C12.9417 5.14062 13.3266 5.52556 13.3266 6.00041C13.3266 6.47525 12.9417 6.86019 12.4668 6.86019H0.859782C0.384937 6.86019 0 6.47525 0 6.00041C0 5.52556 0.384937 5.14062 0.859782 5.14062H12.4668Z" fill="#434D68"></path>
                                                                    <path d="M7.55993 0.251824C7.8957 -0.0839414 8.44008 -0.0839414 8.77584 0.251824L13.9161 5.39204L14.524 6L8.77584 11.7482C8.44008 12.0839 7.8957 12.0839 7.55993 11.7482C7.22416 11.4124 7.22416 10.868 7.55993 10.5323L12.0919 5.99999L7.55993 1.46774C7.24184 1.14965 7.22509 0.644316 7.5097 0.306513L7.55993 0.251824Z" fill="#434D68"></path>
                                                                </svg>
                                                            </a>
                                                        </p>`
                                                    : postBody
                                            }
                                        />
                                    </FundPageContent.Box>
                                </li>
                            );
                        }
                    )}
            </ul>
            <span ref={endRef} className="block pt-10">
                {isLoadingMore && <Spinner className="h-4 w-4" />}
            </span>
        </div>
    );
};

export default Posts;

type ImageData = {
    app_label: "Images";
    content_type: "image";
    entity_id: number;
};
type EmbedData = {
    app_label: "CMS";
    content_type: "embedcode";
    entity_id: number;
    embed_html: string;
};
type PostData = Record<string, ImageData | EmbedData>;

function processPostBodyEmbeds(body?: string): string {
    if (!body) {
        return "";
    }
    const parsedBody = JSON.parse(body) as { HTML: string; data: PostData };
    const regex = /{{\s*EMBEDCODE_(\d+)(?:\|[^}]+)?\s*}}/g;

    const bodyWithEmbed = parsedBody.HTML.replace(
        regex,
        (match, entityIdStr) => {
            const entityId = parseInt(entityIdStr as string, 10);

            const embedDatum = Object.values(parsedBody.data).find(
                (datum) =>
                    datum.content_type === "embedcode" &&
                    datum.entity_id === entityId
            ) as EmbedData | undefined;

            return embedDatum ? embedDatum.embed_html : match;
        }
    );
    return bodyWithEmbed;
}
