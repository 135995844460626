import { decode as htmlDecode } from "html-entities";

import cx from "@hsl/core/utils/cx";

const BASE_STYLES = [
    "text-sm",
    "sm:text-base",
    "[&>*]:text-sm",
    "[&>*]:sm:text-base",
];

const UNORDERED_LIST_STYLES = [
    "[&_ul]:pl-6",
    "[&_ul>li::marker]:text-bullet",
    "[&_ul>li::marker]:text-xs",
    "[&_ul>li::marker]:text-dark-yellow",
    "[&_ul>li]:!list-disc",
    "[&_ul>li]:my-1",
    "[&_ul>li]:sm:my-2",
    "[&_ul>li>strong]:!font-medium",
    "[&>ul>li>strong]:!block",
    "[&_ul:not(:last-child)]:mb-2",
    "[&_ul:not(:last-child)]:sm:mb-4",
];

const ORDERED_LIST_STYLES = [
    "[&_ol]:pl-4",
    "[&_ol]:list-decimal",
    "[&_ol:not(:last-child)]:mb-2",
    "[&_ol:not(:last-child)]:sm:mb-4",
];

const HEADER_STYLES = [
    "[&_h1]:text-lg",
    "[&_h1]:sm:text-2xl",
    "[&_h1]:text-secondary",
    "[&_h1]:font-medium",
    "[&_h1]:pb-1",
    "[&_h2]:text-lg",
    "[&_h2]:text-primary",
    "[&_h2]:font-medium",
    "[&_h2]:mb-0",
    "[&_h2]:pb-2",
    "[&_h2]:pt-3",
    "[&_h3]:text-lg",
    "[&_h3]:text-black",
    "[&_h3]:font-medium",
    "[&_h3]:mb-0",
    "[&_h3]:pb-2",
    "[&_h3]:pt-3",
];

const PARAGRAPH_STYLES = [
    "[&>p:not(:last-child)]:mb-2",
    // "[&>p:not(:first-child)]:mt-2",
    "[&>em]:my-2",
    "[&_q]:text-primary",
    "[&_q]:text-2xl",
    "[&_q]:font-light",
    "[&_q]:leading-8",
    "[&_q]:p-5",
    "[&_q]:block",
    "[&_q]:relative",
    "[&_q::after]:hidden",
    "[&_sup]:text-secondary",
    "[&_sup]:text-sm",
    "[&_sup]:font-light",
    "[&_em]:text-secondary",
];

const ANCHOR_STYLES = [
    "[&_a]:!text-darkBlue",
    "[&_a]:underline",
    // "[&_a]:break-all",
];

const MISC_CLASSNAMES = ["", "", ""];

export interface Props {
    className?: string | string[];
    data?: string;
    decode?: boolean;
}

const TextDataPart = ({ className, data, decode = true }: Props) => {
    if (!data) {
        return null;
    }
    const computedData = decode ? htmlDecode(data) : data;
    return (
        <div
            className={cx(
                BASE_STYLES,
                HEADER_STYLES,
                UNORDERED_LIST_STYLES,
                ORDERED_LIST_STYLES,
                ANCHOR_STYLES,
                PARAGRAPH_STYLES,
                MISC_CLASSNAMES,
                className,
            )}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
                __html: computedData,
            }}
        />
    );
};

export default TextDataPart;
