import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useScrollIntoView } from "@hsl/core/fund-page/hooks";

import { useSectionIntersectionStore } from "./useFundPageSectionObserver";

export type ActiveSectionContextType = {
    activeSection: string | null;
    setActiveSection: (str: string) => void;
    handleSectionClick: (
        sectionKey: string | null,
        disableScroll?: boolean,
    ) => void;
    setObserverDisabled: (bool: boolean) => void;
};

export interface Props {
    children: React.ReactNode;
    scrollIntoView?: boolean;
    delayScroll?: number;
    scrollOffset?: number;
    scrollIntoViewOnLoad?: boolean;
}

export const ActiveSectionContext = createContext<
    ActiveSectionContextType | undefined
>(undefined);

export const ActiveSectionContextProvider = ({
    children,
    scrollIntoView = true,
    delayScroll = 0,
    scrollOffset = 0,
    scrollIntoViewOnLoad = true,
}: Props) => {
    const [observerDisabled, setObserverDisabled] = useState(false);
    const [forceDisableObserver, setForceDisableObserver] = useState(true);
    const [activeSection, setActiveSection] = useState<string | null>(null);
    const [scrollToOnLoad, setScrollToOnLoad] = useState<string>();
    const intersections = useSectionIntersectionStore(
        (state) => state.intersections,
    );
    const handleScrollIntoView = useScrollIntoView();

    // Force active section on click to avoid forcing the intersection state to be ordered
    // Scroll section into view

    const contextValue = useMemo(() => {
        const handleSectionClick = (
            sectionKey: string | null,
            disableScroll?: boolean,
        ) => {
            setObserverDisabled(true);
            setActiveSection(sectionKey);
            if (scrollIntoView && !disableScroll) {
                handleScrollIntoView(
                    `#${sectionKey}`,
                    scrollOffset,
                    delayScroll,
                );
            }
            setTimeout(() => {
                setObserverDisabled(false);
            }, 500);
        };

        return {
            activeSection,
            handleSectionClick,
            setActiveSection,
            setObserverDisabled: setForceDisableObserver,
        } satisfies ActiveSectionContextType;
    }, [activeSection, delayScroll, scrollIntoView, scrollOffset]);

    useEffect(() => {
        const activePageSection = window.location.hash;
        if (activePageSection !== "#intheLoupe") {
            setForceDisableObserver(false);
        }
        if (activePageSection) {
            setScrollToOnLoad(activePageSection.slice(1));
        }
    }, []);

    useEffect(() => {
        if (
            scrollIntoViewOnLoad &&
            scrollToOnLoad &&
            scrollToOnLoad in intersections
        ) {
            // in the Loupe is at the top, so skip
            if (scrollToOnLoad !== "intheLoupe")
                handleScrollIntoView(`#${scrollToOnLoad}`, scrollOffset, 0);
            setScrollToOnLoad(undefined);
        }
    }, [intersections, scrollIntoViewOnLoad, scrollOffset, scrollToOnLoad]);

    useEffect(() => {
        if (activeSection) {
            history.replaceState(
                {},
                "",
                activeSection === "overview" ? "#" : `#${activeSection}`,
            );
        }
    }, [activeSection]);

    useEffect(() => {
        if (!observerDisabled && !forceDisableObserver) {
            setActiveSection(
                Object.entries(intersections).reduce<string | null>(
                    (prev, [key, value]) =>
                        value > (intersections[prev!] ?? 0) ? key : prev,
                    null,
                ),
            );
        }
    }, [forceDisableObserver, intersections, observerDisabled]);

    return (
        <ActiveSectionContext.Provider value={contextValue}>
            {children}
        </ActiveSectionContext.Provider>
    );
};

export function useActiveSectionContext() {
    const documentContext = useContext(ActiveSectionContext);
    if (!documentContext) throw new Error("No ActiveSectionContextProvider");
    return documentContext;
}
