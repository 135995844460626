import { type DocumentSet } from "@hsl/core/documents/types";
import cx from "@hsl/core/utils/cx";
import { useEffect, useState, type CSSProperties } from "react";
import Dropdown from "../../../components/Dropdown";
import { DocumentListItem } from "./DocumentListItem";

interface Props {
    data: DocumentSet[];
    baseURL?: string;
    style?: CSSProperties;
    availableLanguages: string[];
    namingRule?: string;
}

export const DocumentListItemWithShareClassSelector = ({
    data,
    baseURL,
    style,
    availableLanguages,
    namingRule,
}: Props) => {
    const showLanguageSelector = availableLanguages.length > 0;

    const [activeLanguage, setActiveLanguage] = useState<string>();
    const [activeDoc, setActiveDoc] = useState(data[0]);

    useEffect(() => {
        if (activeLanguage) {
            setActiveDoc(data.find((doc) => doc.language === activeLanguage));
        }
    }, [activeLanguage]);

    if (!activeDoc) {
        return null;
    }

    let computedShareClassOptions = data;
    if (showLanguageSelector) {
        computedShareClassOptions = data.filter(
            (doc) => doc.language === activeLanguage
        );
    }

    return (
        <DocumentListItem
            key={activeDoc.id}
            baseURL={baseURL}
            style={style}
            disabled={showLanguageSelector && !activeLanguage}
            namingRule={namingRule}
            {...activeDoc}
        >
            <div className="flex flex-1 flex-wrap items-center sm:flex-[0] sm:flex-nowrap">
                {showLanguageSelector && (
                    <Dropdown<string>
                        placeholder="Select a language"
                        value={activeLanguage}
                        options={availableLanguages}
                        onChange={setActiveLanguage}
                        buttonClassName="min-w-[120px] sm:min-w-[185px] rounded-none rounded-tl-lg rounded-tr-lg sm:rounded-tr-none sm:rounded-bl-lg"
                        className="w-full"
                    />
                )}
                <Dropdown<DocumentSet>
                    placeholder="Select share class"
                    value={
                        showLanguageSelector && !activeLanguage
                            ? undefined
                            : activeDoc
                    }
                    options={computedShareClassOptions}
                    toLabel={(doc) =>
                        Object.values(doc.entity_display_names)[0] as string
                    }
                    onChange={setActiveDoc}
                    disabled={showLanguageSelector && !activeLanguage}
                    buttonClassName={cx(
                        "min-w-[120px] sm:min-w-[185px]",
                        showLanguageSelector &&
                            "rounded-none rounded-bl-lg rounded-br-lg sm:rounded-bl-none sm:rounded-tr-lg"
                    )}
                    className="w-full"
                />
            </div>
        </DocumentListItem>
    );
};
