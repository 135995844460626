export default {
    primary: "#016560",
    secondary: "#434D68",
    spring: "#016560",
    evenlode: "#6A90DC",
    zennor: "#D37E09",
    chelverton: "#393F75",
    cusana: "#99a699",
    offWhite: "#F1F1F1",
    offWhiteDark: "#C1C1C1",
    greenHover: "#009774",
    darkPink: "#B1007E",
    lightPink: "#CA80BB",
    gray: "#DDDDDD",
    corePurple: "#434D68",
    purple: "#4F4F7D",
};
