import cx from "@hsl/core/utils/cx";
import scrollIntoViewWithinElement from "@hsl/core/utils/scrollIntoViewWithinElement";
import { useSidebarContext } from "@hsl/fund-page/hooks";
import { useActiveSectionContext } from "@hsl/fund-page/hooks/useActiveSection";
import { type PageSection } from "@hsl/fund-page/schemas";
import { useEffect, useMemo, useState } from "react";
import useFundPageStore from "../../store/store";
import Banner from "../Banner";
import { Loupe as LoupeIcon } from "../icons";
import NavSkeleton from "./NavSkeleton";
import "./styles.css";
import ClientOnly from "@hsl/components/ClientOnly";

export const Nav = ({ pageSections }: { pageSections: PageSection[] }) => {
    const {
        loading,
        modalOpen,
        config: { fundName },
    } = useFundPageStore(({ loading, modalOpen, config }) => ({
        loading,
        modalOpen,
        config,
    }));
    const { activeSection, handleSectionClick } = useActiveSectionContext();
    const { sidebarOpen } = useSidebarContext();
    const hasScrolled = useHasScrolled(36);
    const loupeCode = fundName
        ?.split(" ")
        .map((w) => w[0]?.toLowerCase())
        .join("");

    useEffect(() => {
        if (activeSection && activeSection !== "intheLoupe") {
            scrollIntoViewWithinElement(activeSection, 100, "x");
        }
    }, [activeSection]);

    const managerDiaryLink = useMemo(() => {
        if (pageSections.some((section) => section.key === "intheLoupe")) {
            return (
                <ManagerDiaryCTA
                    text={
                        pageSections.find((x) => x.key === "intheLoupe")?.title
                    }
                    disabled={activeSection === "intheLoupe"}
                    onClick={() =>
                        handleSectionClick(
                            "intheLoupe",
                            false,
                            loupeCode ? [["loupecode", loupeCode]] : undefined
                        )
                    }
                />
            );
        }
        return null;
    }, [activeSection, handleSectionClick, pageSections]);

    if (loading) {
        return <NavSkeleton />;
    }

    return (
        <div
            className={cx(
                "sticky top-[68px] min-h-[136px] sm:min-h-[144px] md:top-[84px] lg:top-[64px] print:hidden",
                !modalOpen && !sidebarOpen && "z-50"
            )}
        >
            <Banner isStuck={hasScrolled} />
            <nav className="flex w-full items-center justify-between bg-white px-4 sm:px-0">
                <div className="container mx-auto flex h-14 items-center justify-between px-0">
                    <ul className="custom-scroller flex h-full snap-x items-center gap-x-8 overflow-x-auto pr-4 xl:overflow-hidden">
                        {pageSections
                            .filter((section) => section.key !== "intheLoupe")
                            .map((section) => {
                                const isActive = activeSection === section.key;
                                return (
                                    <NavItem
                                        key={section.key}
                                        id={section.key}
                                        isActive={isActive}
                                        label={
                                            section?.shortTitle ??
                                            String(section.title)
                                        }
                                        onClick={() =>
                                            handleSectionClick(section.key)
                                        }
                                    />
                                );
                            })}
                        <li className="snap-center sm:hidden">
                            <ClientOnly>{managerDiaryLink}</ClientOnly>
                        </li>
                    </ul>
                    <div className="hidden sm:block">
                        <ClientOnly>{managerDiaryLink}</ClientOnly>
                    </div>
                </div>
            </nav>
        </div>
    );
};

function useHasScrolled(from = 0) {
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setHasScrolled(window.scrollY > from);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [from]);

    return hasScrolled;
}

interface NavItemProps {
    id: string;
    label: string;
    isActive: boolean;
    onClick(): void;
}

const NavItem = ({ id, label, isActive, onClick }: NavItemProps) => {
    return (
        <li
            id={`nav-item-${id}`}
            className={cx(
                "text-primary flex h-full cursor-pointer snap-center items-center whitespace-nowrap border-b-[3px] pt-1 font-semibold transition sm:text-lg",
                isActive
                    ? "border-primary"
                    : "hover:border-primary/50 border-white"
            )}
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.key === "Enter") onClick();
            }}
        >
            {label}
        </li>
    );
};

const ManagerDiaryCTA = ({
    text,
    disabled,
    onClick,
}: {
    text?: string;
    disabled: boolean;
    onClick(): void;
}) => {
    return (
        <button
            type="button"
            className={cx(
                "bg-hotPink flex h-min w-max items-center rounded-lg px-4 py-2 font-semibold text-white transition",
                !disabled && "hover:bg-vibrantPink"
            )}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.key === "Enter") onClick();
            }}
        >
            <LoupeIcon className="mr-1 hidden" />
            {text}
        </button>
    );
};
