import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";
import { z } from "zod";
import type { Insight } from "../hooks/useInsights";

const newsAndViewsSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});

const postToInsight = (
    post: z.infer<typeof newsAndViewsSchema>["data"][0]
): Insight => {
    const [fragmentA, fragmentB] = post.image.split("/static/");
    const image = `${fragmentA ? fragmentA : fragmentB}`;
    return {
        asAtDate: post.date,
        id: parseInt(`${post.id}`),
        image: image,
        publicationImage: undefined,
        readUrl: post.read_url,
        title: post.title,
        type: post.type,
        category: {
            name: post.category_name,
            colour: post.category_colour,
        },
        authors: [
            {
                id: 0,
                name: post.author_name,
                primaryHeadshot: "",
            },
        ],
        funds: post.funds.map((f) => ({
            id: parseInt(`${f.id}`),
            name: f.name,
        })),
        synopsis: post.synopsis,
        comprehensiveSynopsis: "",
        createdDate: post.date,
        customTags: post.custom_tags.map((ct) => ({
            id: parseInt(`${ct.id}`),
            name: ct.name,
        })),
        featuredStates: [],
        fundGroups: [],
        fundTypes: [],
        updatedDate: post.date,
        validFromDate: post.date,
        body: post.post_body,
    };
};

export default postToInsight;
