import { type ReactNode } from "react";
import dayjs from "dayjs";
import { decode as htmlDecode } from "html-entities";

import cx from "@hsl/core/utils/cx";
// import { PostListItem } from "@hsl/fund-page/schemas";
import { createTemplate } from "@hsl/fund-page/services";

import type { Insight } from "../../hooks/useInsights";
import Card from "../Card";
import FundPageContent from "../FundPageContent";
import {
    GreyCurve,
    NewPage as NewPageIcon,
    NewsAwards,
    NewsEsg,
    NewsInvestmentViews,
    PodcastInterview,
    Webinar as WebinarIcon,
} from "../icons";
import Skeleton from "../Skeletons";
import TextDataPart from "../TextDataPart";

const useReadURL = (id: number) => {
    const head = window.location.pathname.split("/").slice(0, 3).join("/");
    const tail = `Posts/Read/${id}`;
    return `${head}${head.length === 1 ? "" : "/"}${tail}`;
};

const managers = ["Evenlode", "Chelverton", "Zennor", "Spring"] as const;
const figureOutManager = (obj?: any): string => {
    if (!obj || typeof obj !== "object") {
        return "";
    }
    if (Array.isArray(obj)) {
        return obj.reduce(
            (acc: string, cur) =>
                acc.length > 0 ? acc : figureOutManager(cur),
            "",
        );
    }
    return Object.values(obj as Record<string, unknown>).reduce(
        (acc: string, cur) => {
            if (acc.length > 0) {
                return acc;
            }
            if (typeof cur === "object") {
                return figureOutManager(cur);
            }
            const ret = managers.find((m) => `${cur}`.includes(m)) ?? "";
            return ret;
        },
        "",
    );
};

export const NewsAndViewsCard = (insight: Insight) => {
    const {
        id,
        type,
        image,
        category,
        asAtDate,
        title,
        synopsis,
        authors,
        readUrl,
        funds,
        publicationImage,
    } = insight;
    // This will be replaced with Insight.firm
    const _tenant = figureOutManager(insight);
    const tenant = _tenant.length > 0 ? _tenant : "Spring";
    const _readURL = useReadURL(id);

    let safeSynopsis = synopsis;
    if (type === "text_external") {
        const decodedSynopsis = htmlDecode(synopsis);
        const template = createTemplate();
        template.innerHTML = `<div>${decodedSynopsis}</div>`;
        safeSynopsis =
            (template.content.children[0] as HTMLElement)?.textContent ??
            decodedSynopsis;
    }

    let CardIcon: ReactNode;
    switch (category.name) {
        case "Interview or Podcast":
            CardIcon = <PodcastInterview width={40} height={40} />;
            break;
        case "Awards":
            CardIcon = <NewsAwards width={40} height={40} />;
            break;
        case "ESG":
            CardIcon = <NewsEsg width={40} height={40} />;
            break;
        case "In the Press":
            CardIcon = <WebinarIcon width={40} height={40} />;
            break;
        default:
            CardIcon = <NewsInvestmentViews width={40} height={40} />;
    }

    return (
        <Card.Container
            href={readUrl.length === 0 ? _readURL : readUrl}
            newTab={type === "text_external"}
        >
            <Card.Image
                imgURL={
                    image && image?.length > 0
                        ? `/static/${image}`
                        : "/static/images/post-card-default.jpg"
                }
            >
                <div
                    className={cx(
                        "bg-primary",
                        {
                            "bg-evenlode": tenant === "Evenlode",
                            "bg-zennor": tenant === "Zennor",
                            "bg-chelverton": tenant === "Chelverton",
                            "bg-spring": tenant === "Spring",
                        },
                        "absolute -bottom-5 left-4 rounded-full p-1.5",
                    )}
                >
                    {CardIcon}
                </div>
            </Card.Image>
            <Card.Body className="pt-8">
                <Card.Brow>
                    <span className="uppercase">{category.name}</span>
                    {" | "}
                    <span>{dayjs(asAtDate).format("D MMMM YYYY")}</span>
                </Card.Brow>
                <Card.Title className="mb-auto pt-1">
                    {htmlDecode(title)}
                </Card.Title>
                <TextDataPart className="flex-1" data={safeSynopsis} decode />
                <Card.Footer className="h-16">
                    {publicationImage?.src ? (
                        <div className="flex justify-between pt-2">
                            <img
                                src={`/static/` + publicationImage?.src}
                                alt={publicationImage.alt}
                                className="max-h-[45px] max-w-[150px]"
                            />
                            <NewPageIcon />
                        </div>
                    ) : authors.length > 0 ? (
                        <>
                            <div className="font-semibold">
                                {authors.map(({ name }) => name).join(" & ")}
                            </div>
                            <div>{funds[0]?.name}</div>
                        </>
                    ) : null}
                </Card.Footer>
            </Card.Body>
        </Card.Container>
    );
};

export const NewsAndViewsCardSkeleton = ({
    className,
}: {
    className?: string;
}) => <Skeleton className={className} width={"378px"} height={"533px"} />;

export const NewsAndViewsCardListSkeleton = ({
    withCurve = true,
    className,
    numCards = 3,
}: {
    withCurve?: boolean;
    className?: string;
    numCards?: number;
}) => (
    <>
        <div className={className}>
            {withCurve && <GreyCurve className="mx-auto -mb-1" />}
            <div className="bg-offWhite mx-auto pb-6">
                <div className="container mx-auto">
                    <FundPageContent.HeaderSkeleton height={"2.5rem"} />
                    <div className="flex flex-wrap gap-6 pt-8">
                        {Array.from(Array(numCards)).map((_, i) => (
                            <NewsAndViewsCardSkeleton
                                key={`homeNewsAndViewsSkel-${i}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
);
