import Vimeo from "@u-wave/react-vimeo";
import { decode as htmlDecode } from "html-entities";
import Balancer from "react-wrap-balancer";
import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import {
    dataPartSchema,
    documentPartDataSchema,
    simplePartSchema,
    textCollectionPartSchema,
    textPartSchema,
    type DocumentPartDataSchema,
    type SimplePartSchema,
} from "@hsl/fund-page/schemas";

import Button from "../../../components/Button";
import FundPageContent from "../../../components/FundPageContent";
import {
    ArrowRightGreen,
    Document as DocumentIcon,
} from "../../../components/icons";
import Skeleton from "../../../components/Skeletons";
import TextDataPart from "../../../components/TextDataPart";
import useFundPageStore from "../../../store/store";

export const introSectionSchema = dataPartSchema.extend({
    part: z.object({
        introTitle: textPartSchema,
        introText: textCollectionPartSchema,
        incomeAimText: textCollectionPartSchema,
        video: z.object({
            part: z.object({
                data: z.object({
                    src: z.string(),
                    title: z.string(),
                    fallback: z.string().optional(),
                }),
            }),
        }),
        fundSummary: documentPartDataSchema.optional(),
        introLink: simplePartSchema,
    }),
});

const IntroSection = ({ part }: z.infer<typeof introSectionSchema>) => {
    const {
        introTitle,
        introText,
        video,
        fundSummary,
        incomeAimText,
        introLink,
    } = part;
    return (
        <>
            <FundPageContent.Header className="mb-0 mt-2">
                {introTitle.part.data}
            </FundPageContent.Header>
            <div className="flex flex-col sm:flex-row sm:pt-6">
                <div className="flex max-w-full flex-[3] flex-col">
                    <div className="relative">
                        <Skeleton className="absolute inset-0 z-10 !m-0 hidden h-full sm:block" />
                        {video.part.data?.src ? (
                            <Vimeo
                                className="relative z-20 flex sm:block"
                                video={video.part.data.src}
                                showTitle={false}
                                height={245}
                                controls={true}
                                loop={true}
                                autoplay={true}
                                muted={true}
                                color="016560"
                                id="springPlayer"
                                textTrack="en"
                            />
                        ) : (
                            <img src={video.part.data?.fallback} />
                        )}
                    </div>
                    <TextDataPart
                        className={cx(
                            "bg-offWhite",
                            "p-4",
                            "sm:mt-4",
                            "[&_strong]:text-secondary",
                            "[&_strong]:text-sm",
                            "[&_em]:not-italic",
                            "[&_em]:text-sm",
                            "[&>p:last-of-type]:flex",
                            "[&>p:last-of-type]:justify-end",
                            "[&>p:last-of-type]:gap-2",
                        )}
                        data={incomeAimText.part.data.text}
                    />
                </div>
                <div className="flex flex-[2] flex-col justify-between pt-4 sm:pl-4 sm:pt-0">
                    <div className="flex flex-col sm:flex-col-reverse">
                        {/* TextDataPart + cx + twind not including styles in this manner */}
                        <div
                            className={cx(
                                "text-sm sm:text-base",
                                "[&>p:not(:last-child)]:pb-2",
                                "[&>p:not(:last-child)]:mb-2",
                                "[&>p:not(:last-child)]:border-b",
                                "[&>p:not(:last-child)]:border-secondary/20",
                            )}
                            dangerouslySetInnerHTML={{
                                __html: htmlDecode(introText.part.data.text),
                            }}
                        />
                        {fundSummary?.part.data[0]?.href && (
                            <FundSummaryCTA
                                {...fundSummary}
                                key={fundSummary.key}
                            />
                        )}
                    </div>
                    <IntroLinks {...introLink} />
                </div>
            </div>
        </>
    );
};

export default IntroSection;

function IntroLinks({ part }: SimplePartSchema) {
    if (!Array.isArray(part.data?.links)) {
        return null;
    }
    const imgSrc = part.data.image;
    return (
        <div className="flex flex-col gap-y-2 sm:pt-4">
            {part.data.links.map((link, i) => (
                <div
                    key={`intro-link-${link.id}-${i}`}
                    className="flex items-center"
                >
                    {imgSrc ? (
                        <img
                            src={imgSrc}
                            alt="company-logo"
                            className="mr-1.5 w-[18px]"
                        />
                    ) : (
                        <ArrowRightGreen className="mr-1.5" />
                    )}
                    <Balancer className="leading-3">
                        <a
                            href={link.url}
                            className="text-primary text-sm font-semibold hover:underline"
                        >
                            {link?.text}
                        </a>
                    </Balancer>
                </div>
            ))}
        </div>
    );
}

function FundSummaryCTA({ part, title }: DocumentPartDataSchema) {
    const { base_url } = useFundPageStore((store) => store.config);
    return (
        <Button
            openInNewTab
            href={`${base_url}${part.data[0]?.href}`}
            className="bg-primary my-4 mt-4 px-4 py-2 font-light text-white sm:mt-0"
        >
            <DocumentIcon />
            <span className="ml-1.5">{title}</span>
        </Button>
    );
}
